import React from "react";
import ClockIcon from "./ClockIcon";

const CalenderTile = ({ data }) => {
  return (
    <div
      className="relative flex w-full h-auto bg-blue-100 rounded-lg"
      style={{ marginLeft: "5px", height: "160px" }}
    >
      <div
        className="flex-1 bg-blue-900 text-white font-bold flex flex-col items-center justify-center rounded-lg "
        style={{ width: "70%" }}
      >
        <p className="flex flex-col items-center text-xl">
          <span>{data.date}</span>
          <span> {data.month}</span>
        </p>
      </div>
      <div
        className="flex flex-col justify-center px-2"
        style={{ width: "70%" }}
      >
        <div className="flex items-center">
          <ClockIcon className="w-4 h-4 mr-1" />
          <span className="text-sm ">{data.time}</span>
        </div>
        <h3 className="text-sm font-bold text-theme-blue hover:text-theme-yellow">
          <a href={data.location} target="_blank">
            {data.caption}
          </a>
        </h3>
        <div className="event-one__text">
          <p className="text-sm">{data.venue}</p>
        </div>
      </div>
    </div>
  );
};

export default CalenderTile;
