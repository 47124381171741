import React from "react";

const ClockIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#fff"
        d="M7.5 0.5A7 7 0 1 0 7.5 14.5A7 7 0 1 0 7.5 0.5Z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M7.5,1C11.1,1,14,3.9,14,7.5S11.1,14,7.5,14S1,11.1,1,7.5S3.9,1,7.5,1 M7.5,0C3.4,0,0,3.4,0,7.5 S3.4,15,7.5,15S15,11.6,15,7.5S11.6,0,7.5,0L7.5,0z"
      ></path>
      <path
        fill="#66798f"
        d="M7.5 6.5A1 1 0 1 0 7.5 8.5A1 1 0 1 0 7.5 6.5Z"
      ></path>
      <path
        fill="#66798f"
        d="M9.5 10.8L6.9 7.6 8.9 2.3 9.8 2.7 8.1 7.4 10.3 10.1z"
      ></path>
    </svg>
  );
};

export default ClockIcon;
