import React, { useEffect, useState } from "react";
import CalenderTile from "./CalenderTile";
import Slider from "react-slick";

function App() {
  const [events, setEvents] = useState(null);
  const baseAPIPath = window.techsaDiscussionSettings.baseAPIPath;
  const apiUrl = baseAPIPath + "/event-calendar"; // Replace with your API URL

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(apiUrl);
        const result = await response.json();
        setEvents(result.data);
        console.log(result);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <>
      {events ? (
        <div className="mt-4 space-y-2">
          <Slider {...settings}>
            {events?.map((event, index) => (
              <CalenderTile data={event} />
            ))}
          </Slider>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default App;
